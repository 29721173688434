<script setup>
// TODO: [FIXED][BUG] После валидации формы шевроны пропадают + разный размер галочек
// TODO: [FEATURE] Добавить подсказки после валидации
// TODO: [CHECK] Проверить все props на всех полях:
  // TODO: Single Line of text
  // TODO: Multi Line of text
  // TODO: Select
  // TODO: Ref select
  // TODO: ...



import { ref, onBeforeMount } from "vue";
import axios from "axios";
import PageNotFound from "../views/PageNotFound.vue";
import FormWebPart from "../components/FormWebPart.vue";
import { globals } from "@/main.js";

const json = ref({});
const loading = ref(true);

onBeforeMount(() => {
  axios
    .get(`newIncident.json`)
    .then(resp => {
      json.value = resp.data;
      loading.value = false;
    })
    .catch(error => {
      loading.value = false;
      globals.$toast(
        "Ошибка",
        "Не возможно загрузить данные формы: " + error.message,
        5000,
        "error"
      );
    });
});
</script> 

<template>
  <div class="container col col-md-6 show-font">
    <div v-if="loading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Загружаю...</span>
      </div>
    </div>
    
    <FormWebPart v-else-if="Object.keys(json).length > 0" :data="json" buttonText="Создать обращение" headerText="Заполните следующие поля:"/>

    <div v-else>
      <PageNotFound elementType="form"/>
    </div>
  </div>
</template>



<style>
li {
  background: transparent !important;
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e") !important;
}

.form-select {
    background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none) !important;

}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}
</style>

import axios from 'axios'
import { globals } from '@/main.js'
import router from '@/router/index.js'


const base_url = `${process.env.VUE_APP_BASE_API}/api/v1`;


const removeEmptyValues = (data) => {
    Object.keys(data).forEach(key => {
        if (data[key] === "") {
            delete data[key]
        }
    })
    return data
}
const tamName = async (id, lb) => {
    const token = localStorage.getItem("token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
    };
    const users = await axios
        .get(`${base_url}/users/get-users`, { headers })
        .catch(() => {
            globals.$toast('Возникла ошибка', 'Обращение не созданно, или созданно с ошибкой. Свяжитесь с администратором сайта', 5000, 'error')
            lb.loading = false
            router.push("/")
        })
    const tam = users.data.filter(user => { return user.id == id })[0]
    return tam.name
}
const columnsToFullDescription = async (json, lb) => {

    const tam = await tamName(json.initiator_id,lb)

    const vendors = (json) => {
        let output = ''
        if (json.vendors.length) {
            json.vendors.forEach(v => {
                if (v.category.length) {
                    if (v.category.filter(cm => cm.selected === true).length) {
                        output += "\t" + v.name + "\n"
                        v.category.filter(cm => cm.selected === true).forEach(c => {
                            output += "\t\t" + c.name + "\n"
                        })
                    }
                }
            })
        }
        return output
    }

    const authorizeUsers = (json) => {
        let output = ''
        output += json.authorizeUser1_fio + "\t" + json.authorizeUser1_phone + "\t" + json.authorizeUser1_email + "\n"
        if ('authorizeUser2_fio' in json) {
            output += json.authorizeUser2_fio + "\t" + json.authorizeUser2_phone + "\t" + json.authorizeUser2_email + "\n"
        }
        if ('authorizeUser3_fio' in json) {
            output += json.authorizeUser3_fio + "\t" + json.authorizeUser3_phone + "\t" + json.authorizeUser3_email + "\n"
        }
        if ('authorizeUser4_fio' in json) {
            output += json.authorizeUser4_fio + "\t" + json.authorizeUser4_phone + "\t" + json.authorizeUser4_email + "\n"
        }
        if ('authorizeUser5_fio' in json) {
            output += json.authorizeUser5_fio + "\t" + json.authorizeUser5_phone + "\t" + json.authorizeUser5_email + "\n"
        }
        if ('json.remove_access_tool' in json) {
            output += "Какое ПО может быть использовано для подключения к инфраструктуре и взаимодействия с Заказчиком:\t" + json.remove_access_tool | "N/A"
        }
        return output
    }


    return "\nНазвание заказчика:\t" + json.customer + "\n\
    ИНН:\t" + (json.INN || null) + "\n\
    Идентификатор контракта:\t" + json.contract_id + "\n\
    Количество часов поддержки:\t" + json.support_hours + "\n\
    Технический менеджер по работе с клиентами(TAM):\t" + tam + "\n\
    Дата начала и окончания поддержки:\t" + json.support_from + " - " + json.support_to + "\n\
    Тип услуги:\t" + (json.service_type || null) + "\n\
    Список поддерживаемых продуктов в рамках контракта:\n" + vendors(json) + "\
    Список авторизованных пользователей со стороны Заказчика, которым необходимо предоставить доступ в личный кабинет:\n" + authorizeUsers(json)
}

export async function submit(data, lb) {
    // Form validation START -------------------------------------------------------------
    let isEmptyRequiredFields = false
    let elements = document.querySelectorAll('.form-control')
    elements.forEach(elem => {
        if (elem.required === true && elem.value === "") {
            isEmptyRequiredFields = true
        }
    })

    if (isEmptyRequiredFields) {
        lb.loading = false;
        const forms = document.querySelectorAll('.needs-validation')
        Array.from(forms).forEach(form => {
            form.classList.add('was-validated')
        })
        globals.$toast('Ошибка заполнения полей', 'Не все поля заполнены или заполнены не верно', 5000, 'error')
        return
    }

    let forms = document.querySelectorAll("form")
    Array.from(forms).forEach(form => {
        form.addEventListener('submit', event => {
            if (!isEmptyRequiredFields) {
                event.preventDefault()
                event.stopPropagation()
            }
        }, false)
    })
    // Form validation END -------------------------------------------------------------

    // let token = localStorage.getItem("token")
    let body = {}
    // const headers = {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`
    // }
    removeEmptyValues(data)
    const { formHeader, ...dataWithoutformHeader } = data

    if (data.formHeader.category === "Контракт") {
        // Check avaliable Priority
        // axios.get(`${process.env.VUE_APP_BASE_API}/api/v1/tickets/priority?user_id=${data.initiator_id}`, {headers}).then(resp=>{console.log("resp from axxios priority");console.log(resp);})
        body = {
            short_content: formHeader.subtitle + " " + data.customer,
            full_content: await columnsToFullDescription(dataWithoutformHeader, lb),
            initiator_id: parseInt(data.initiator_id),
            category_id: 100,
            priority_id: 4,
            initiator_additional_contacts: null,
            name: null,
            phone: null,
            email: null,
            watchers: null,
            ticket_id: null,
            accountant_group_id: 2,
        }
    }
    else {
        body = {
            short_content: data.short_content,
            full_content: data.full_content,
            initiator_id: data.initiator_id,
            category_id: data.category_id,
            priority_id: data.priority_id,
            initiator_additional_contacts: data.phone ? data.phone : null,
            name: data.contractor_name ? data.contractor_name : null,
            phone: data.phone ? data.phone : null,
            email: data.email ? data.email : null,
            watchers: data.watchers ? data.watchers : null,
            ticket_id: null,
        }
    }
    const token = localStorage.getItem("token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
    };
    axios
        .post(`${process.env.VUE_APP_BASE_API}/api/v1/tickets/create`, body, { headers })
        .then(resp => {
            data.ticket = resp.data
        })
        // ? Where correctly added CATCH if we have two THEN in a row
        .catch(error => {
            console.log(error)
            globals.$toast('Ошибка создания обращения', 'Обратитесь к администратору сайта. Текст ошибки: ' + error.message, 5000, 'error')
            lb.loading = false
        })
        .then(() => {
            if (data ?.ticket !== undefined) {
                if (data.file) {
                    var data_ = new FormData();
                    data_.append("file", data.file, data.file.name);
                    const headers = { Authorization: `Bearer ${token}` }

                    axios
                        .post(`${process.env.VUE_APP_BASE_API}/api/v1/tickets/upload?ticket_id=${data.ticket.id}&author_id=${data.ticket.initiator_id}`, data_, { headers })
                        .then(() => {
                            globals.$toast('Успех', 'Обращение успешно создано', 5000, 'success')
                            router.push(`ticket/${data.ticket.id}`)
                        })
                        .catch(() => {
                            globals.$toast('Предупреждение', 'Обращение успешно создано, но фаил не был прекреплен', 5000, 'warning')
                            lb.loading = false
                            router.push(`ticket/${data.ticket.id}`)

                        })
                }
                else {
                    globals.$toast('Успех', 'Обращение успешно создано', 5000, 'success')
                    router.push(`ticket/${data.ticket.id}`)
                }
            }
        })

}